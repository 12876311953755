<template>
  <div>
    <div class="" v-if="pageView == 'LoginView'">
              <div class="new-form-element">
                <p class="m-0 pl-1">
                  Email address <span style="color: #969696">*</span>
                </p>
                <div class="NewFormInput d-flex align-items-center w-100">
                  <input
                    type="email"
                    class="flex-grow-1 m-0"
                    placeholder="example@mail.com"
                    v-model="loginDetail.email"
                  />
                </div>
              </div>
              <div class="new-form-element mt-3 ">
                <p class="m-0 pl-1">
                  Password <span style="color:#969696">*</span>
                </p>
                <div class="NewFormInput d-flex align-items-center justify-content-center w-100">
                  <input
                    @keyup.enter="LogIn"
                    :type="passwordFieldType"
                    class="flex-grow-1 m-0"
                    placeholder="Password"
                    v-model="loginDetail.password"
                  />
                  <div
                    style="cursor: pointer"
                    class=""
                    v-if="passwordFieldType == 'text'"
                  >
                    <b-icon-eye style="color: #d1d1d1" @click="switchPass" />
                  </div>
                  <div
                    style="cursor: pointer"
                    class=""
                    v-else
                    @click="switchPass"
                  >
                    <b-icon-eye-slash style="color: #d1d1d1" />
                  </div>
                </div>
              </div>
              <div class="mt-4  w-100 text-center">
                <button
                  :disabled="!isLoginValid"
                  class="btn w-100 NewFormInput-Btn btn-padding"
                  @click="LogIn"
                >
                  <span v-if="!loginSpinner">Sign In</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>

                <div class="mt-2 d-flex justify-content-center">
                  <router-link to="/password-reset" style="    color: #7A7A7A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;">Forgot Password?</router-link>
                </div>
              </div>
</div>

<div v-if="pageView == 'workSpacesView' && workSpaces.length > 0">
<Workspace :workSpaces="workSpaces"/>
</div>
  </div>

  </template>
  
  <script>
  import axios from 'axios'
  import { BIconEyeSlash, BIconEye } from "bootstrap-vue";
//   import SignUpComp from '~/components/GlobalComponents/SignUpComp.vue';
  export default {
    name: "Login",
    
    components: {
      BIconEyeSlash,
      BIconEye,
      Workspace: () =>
      import(
        /* webpackChunkName: "Workspace" */ "./Workspace.vue"
      ),
    },
  mounted(){
      this.$emit('hideApps',false);
  },
    data() {
      return {
        loginDetail: {
          password: "",
          email: "",
        },
        passwordFieldType: "password",
        loginSpinner: false,
        isLoading:false,
        workSpaces:[],
        pageView:"LoginView"
      };
    },

    methods: {
      switchPass() {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      },
      async fetchWorkSpaces(){
        try {
          this.loginSpinner = true;

          const response = await axios.get('/workspaces')
if(response.status == 200){
  
  this.workSpaces = response.data.data
  if(this.workSpaces.length > 0){
    
  this.pageView = 'workSpacesView'
  }
}
        } catch (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
          throw error;
        }finally{
          this.loginSpinner = false;
        }
       
      },
      async LogIn() {
        if (this.isLoginValid) {
          this.loginSpinner = true;
          try {
            let data = {
              email: this.loginDetail.email,
              password: this.loginDetail.password,
            };
            const response = await axios.post(`/login`, data);
            if (response.status == 201 && response.data.status == "fail") {
              const Error = response.data.data;
              
              if (typeof Error == "object") {
                for (const key in Error) {
                  this.$toasted.show(`${Error[key]}`, {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration: 2000,
                  });
                }
              } else if (typeof Error == "string") {
                this.$toasted.show(`${Error}`, {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              } else {
                this.$toasted.show("Error occured", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 4000,
                });
              }
            } else if (response.status == 200) {

              let { access_token , show_workspaces} = response.data

              if (access_token) {
                localStorage.setItem(
                  "user_access_token",
                  response.data.access_token
                );

                if(
                show_workspaces
              ){
                this.fetchWorkSpaces()
              } else{

                this.$router.push("/manage/quizzes").then(() => { this.$router.go(0) })
                
              }
                
              }
              

             
            } else if (response.status == 206) {
              this.$toasted.show(`${response.data.error}`, {
                theme: "toasted-primary",
                type: "error",
                position: "bottom-center",
                duration: 4000,
              });
  
              localStorage.setItem(
                "user_access_token",
                response.data.access_token
              );
              this.$router.push("/email-verify");
            }
          } catch (error) {
            if(error){
              const Error = error.response.data;
              if (typeof Error == "object") {
                
                  this.$toasted.show(`${Error.message}`, {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration: 2000,
                  });
                
              } else if (typeof Error == "string") {
                this.$toasted.show(`${Error}`, {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              } else {
                this.$toasted.show("Error occured", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 4000,
                });
              }
       
          throw error;
            }
            
          } finally {
            this.loginSpinner = false;
          }
        } else {
          this.$toasted.show("Invalid Form", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      },
      async getUser() {
        if(process.client){
  
        
        try {
          const response = await axios.get(`/getCurrentUser`);
          if (response.status == 200) {
            localStorage.setItem(
              "loggedInUser",
              JSON.stringify(response.data.data)
            );
          }
        } catch (error) {
          if (error.response.status == 401) this.User = {};
          localStorage.removeItem("user_access_token");
        }
      } },
      
    },
    computed: {
      isLoginValid() {
        return this.loginDetail.email !== "" && this.loginDetail.password !== "";
      },
    },
  };
  </script>
  <style scoped>
    .btn-padding{
    padding: 14px 24px;
  }
.NewFormInput-Btn{
  
        background: #4D1B7E;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:hover{
        background: #FFC000;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:disabled:hover {
        background: #4d1b7e !important;
      }
  </style>
  